import se from "../../../public/media/panel/3a134ba732046a809a25dba48f11f75b/js/vuedraggable";

const menuToggler = document.querySelector('.menu-toggler');
const menuCollapse = document.querySelector('.menu-collapse');
const searchBar = document.querySelector('.search-bar');


menuToggler.addEventListener('click', () =>{

    if(menuCollapse.classList.contains('active')){
        searchBar.style.height = null;
    }

    menuToggler.classList.toggle('closed');
    menuCollapse.classList.toggle('active');

});

const bigLogo = document.querySelector('.big-logo');
const header = document.querySelector('header');
const homeMain = document.querySelector("#home main");

if(homeMain) {
    homeMain.addEventListener('scroll', () => {
        if (homeMain.scrollTop > 72) {
            header.classList.add('show');
            bigLogo.classList.remove('show');
        } else {
            header.classList.remove('show');
            bigLogo.classList.add('show');
        }
    });
}


const searchLink = document.querySelector('.rechercher');
const closeSearch = document.querySelector(".close-search");

searchLink.addEventListener('click', function (){
    searchBar.style.height = "auto";
});

closeSearch.addEventListener('click', function (){
    searchBar.style.height = null;
});

window.addEventListener("resize", function (){
   searchBar.style.height = null;
});